import React from "react";

function SocialMedia() {
    return (
        <div className="row">
            <div className="ft-brand">
                <h3>Chance Trades</h3>
                <br />
                <div className="container">
                    <a
                        href="https://www.instagram.com/chance_trades/"
                        className="media-lnk"
                    >
                        <img
                            src="../instagram-logo.cc36d343.png"
                            className="ml-auto ft-brand-img"
                            width="25px"
                            alt="instagram"
                        />
                    </a>
                    <a
                        href="https://twitter.com/ChanceTrades"
                        className="media-lnk"
                    >
                        <img
                            src="../twitter-icon.2266f763.svg"
                            className="ml-auto ft-brand-img"
                            width="25px"
                            alt="twitter"
                        />
                    </a>
                    <a
                        href="https://www.facebook.com/ChanceTrades/"
                        className="media-lnk"
                    >
                        <img
                            src="../facebook-icon.7ee259f0.svg"
                            className="ml-auto ft-brand-img"
                            width="25px"
                            alt="facebook"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

function Disclaimer() {
    return (
        <div className="ft-disc">
            Copyright 2022 Chance Trades. All Rights Reserved.
            <br />
            <br />
            By continuing to use this website, you agree to the Privacy Policy,
            Cookie Use Policy and Terms of Services of Chance Trades.
            <br /> <br /> ‍ Legal Disclaimer: Chance Holdings LLC ("Chance
            Trades") is not a registered investment advisor nor is it licensed
            as such with any federal or state regulatory agency. Chance Trades
            does not manage client financial assets in any way. Chance Trades is
            not a fudiciary. Information provided and opinions expressed on this
            website and/or on the DISCORD server does not constitute investment
            advice. The ideas expressed on this site and related services are
            solely the opinions of Chance Trades and/or the result of
            technologies you the customer use and serve as ideas for educational
            purposes only. We advise everyone to know the risks involved with
            trading stocks, ETFs and options. We encourage every visitor to the
            website AND/OR DISCORD to do his/her own research and to consult
            with his/her own financial advisor(s) prior to engaging in any
            investing activities, and to make sure he/she knows all the
            potential risks involved. Any investment decision that results in
            losses or gains made based on any information on this site or
            related services is not the responsibility of Chance Holdings LLC
            (Chance Trades). Chance Holdings LLC (Chance Trades) is solely a
            trading community, a technology/data-provider and not a financial
            service.
        </div>
    );
}

function Legal() {
    return (
        <div className="row legal">
            <div className="col">
                <a className="legal-lnk lnk-div" href="/ts">
                    Terms of Service
                </a>
            </div>
            <div className="col">
                <a className="legal-lnk lnk-div" href="/privacy">
                    Privacy Policy
                </a>
            </div>
            <div className="col lnk-div">
                <a className="legal-lnk" href="/cookie">
                    Cookie Use Policy
                </a>
            </div>
        </div>
    );
}
const Footer = () => {
    return (
        <div className="footer-wrapper">
            <SocialMedia />
            <div className="ft-divider"></div>
            <Disclaimer />
            <Legal />
        </div>
    );
};

export default Footer;
