import "./App.css";
import React from "react";
import { Outlet, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TS from "./pages/TS";
import Privacy from "./pages/Privacy";
import Cookie from "./pages/Cookie";
import Footer from "./components/Footer";

function MenuItem(props) {
    return (
        <a href={props.href} className="icon">
            <div className={props.icon} />
            <p>{props.text}</p>
        </a>
    );
}

function Menu(props) {
    if (props.profile === null) return <span>loading...</span>;
    return (
        <div id="menu" className="row d-none d-md-flex">
            <div className="col-sm">
                <MenuItem
                    icon="youtube"
                    href="https://www.youtube.com/c/TheChancesTaken"
                    text="YouTube"
                />
            </div>
            <div className="col-sm">
                <MenuItem
                    icon="options_sweeps"
                    href="https://callsorputs.com"
                    text="Options Sweeps"
                />
            </div>
            <div className="col-sm-4">
                <MenuItem icon="logo" href="/" text="" />
            </div>
            <div className="col-sm">
                <MenuItem
                    icon="manage_plans"
                    href="/manage"
                    text="Manage Plans"
                />
            </div>
            {props.profile.logged_in === true ? (
                <div className="col-sm">
                    <MenuItem icon="discord" href="/logout" text="Logout" />
                </div>
            ) : (
                <div className="col-sm">
                    <MenuItem
                        icon="discord"
                        href="/login"
                        text="Sign Up/Sign In"
                    />
                </div>
            )}
        </div>
    );
}

function Navbar(props) {
    if (props.profile === null) return <span>loading...</span>;
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark d-md-none">
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarTogglerDemo01"
                >
                    <a className="navbar-brand" href="/">
                        Chance Trades
                    </a>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://www.youtube.com/c/TheChancesTaken"
                            >
                                YouTube
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://callsorputs.com/"
                            >
                                Options Sweeps
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/manage">
                                Manage Plans
                            </a>
                        </li>
                        {props.profile.logged_in === true ? (
                            <li className="nav-item">
                                <a className="nav-link" href="/logout">
                                    Logout
                                </a>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <a className="nav-link" href="/login">
                                    Sign In
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

const Layout = (props) => {
    return (
        <>
            <div className="container">
                <Navbar profile={props.profile} />
                <Menu profile={props.profile} />
                <Outlet />
                <Footer />
            </div>
        </>
    );
};
function App() {
    const [userData, setUserData] = React.useState({ logged_in: false });
    React.useEffect(() => {
        fetch("/user-profile")
            .then((res) => res.json())
            .then((data) => setUserData(data));
    }, []);
    return (
        <Routes>
            <Route path="/" element={<Layout profile={userData} />}>
                <Route index element={<Home />} />
                <Route path="ts" element={<TS />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="cookie" element={<Cookie />} />
                <Route path="*" element={<Home />} />
            </Route>
        </Routes>
    );
}

export default App;
