import React, { useState } from "react";
import axios from "axios";

function Hero() {
    return (
        <div className="row">
            <div
                id="hero"
                className="h-100 d-flex align-items-center justify-content-center   "
            >
                <div id="hero-info" className="col-sm-6 col-12 ">
                    <h1>Learn. Educate. Grow.</h1>
                    <p>
                        Financial Freedom begins with your curiosity, and ends
                        with your execution. Chance Trades end goal is to
                        provide the public with the knowledge that they seek so
                        that you do not need to work that job you despise; do
                        not miss out on a possible route to freedom. It is
                        there, the question is, are you willing to put in the
                        work? The next goal is for you to pay it forward, and
                        give someone the helping hand to the same freedom you
                        sought for and attained.
                    </p>
                    <a href="https://www.chancetrades.com/discord">
                        <button className="btn btn-lg">Join Our Discord</button>
                    </a>
                </div>
            </div>
        </div>
    );
}
function SubscribeArea() {
    const [input, setInput] = useState("");
    const [placeholder, setPlaceholder] = useState("Enter your email address");
    const onChange = (e) => {
        setInput(e.currentTarget.value);
    };
    const submitEmail = async () => {
        try {
            let row = { email: input };

            // eslint-disable-next-line
            const result = await axios.post("/create-contact", row);
            setInput("");
            setPlaceholder("Thank you for subscribing!");
            setTimeout(function () {
                //Start the timer
                setPlaceholder("Enter your email address");
            }, 1500);
        } catch (e) {
            setInput("");
            console.error("Error: ", e);
            setPlaceholder("Error. Please try again later.");
            setTimeout(function () {
                //Start the timer
                setPlaceholder("Enter your email address");
            }, 1500);
        }
    };
    return (
        <div className="subscribe-area">
            <div className="row">
                <div className="col-md-4">
                    <div className="subscribe-text mb-15">
                        <span>JOIN OUR NEWSLETTER</span>
                        <h2>Subscribe to the Chance Trades Newsletter</h2>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="subscribe-wrapper subscribe2-wrapper mb-15">
                        <div className="subscribe-form">
                            <input
                                id="email"
                                value={input}
                                className="emailInput"
                                placeholder={placeholder}
                                type="email"
                                onChange={onChange}
                            ></input>
                            <button onClick={submitEmail}>
                                Subscribe{" "}
                                <i className="fas fa-long-arrow-alt-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function PricingCard1() {
    return (
        <div id="card" className="trader">
            <h1>Trader</h1>
            <div id="person" />
            <ul>
                <li>Discord Colored Name</li>
                <li>Exclusive Premium Chatroom</li>
                <li>Buy/Sell Ideas Equities/Options</li>
            </ul>
            <h2>$20/week</h2>
            <div className="trader-space"></div>
            <a href="https://www.chancetrades.com/checkout?plan=td">
                <button className="btn btn-lg">Choose Trader</button>
            </a>
        </div>
    );
}

function PricingCard1Mobile() {
    return (
        <div id="cardmobile" className="trader container-fluid marginless body">
            <div className="padding" />
            <div className="row">
                <div className="col-5 marginless">
                    <div id="image">
                        <div id="person" />
                    </div>
                    <h2>
                        <b>$20/week</b>
                    </h2>
                </div>
                <div className="col-7 marginless">
                    <h1>
                        <b>Trader</b>
                    </h1>
                    <ul className="list-unstyled">
                        <li>Discord Colored Name</li>
                        <li>Exclusive Premium Chatroom</li>
                        <li>Buy/Sell Ideas Equities/Options</li>
                    </ul>
                    <a href="https://www.chancetrades.com/checkout?plan=td">
                        <button className="btn btn-lg">Choose Trader</button>
                    </a>
                </div>
            </div>
            <div className="padding" />
        </div>
    );
}

function PricingCard2() {
    return (
        <div id="card" className="analyst">
            <h1>Analyst</h1>
            <div id="person" />
            <ul>
                <li>All of Trader Tier PLUS</li>
                <li>Watchlist</li>
                <li>Specific Trade Options</li>
            </ul>
            <h2>$50/month</h2>
            <span>or</span>
            <h2>$500/year</h2>
            <a href="https://www.chancetrades.com/checkout?plan=an">
                <button className="btn btn-lg">Choose Monthly</button>
            </a>

            <div></div>
            <a href="https://www.chancetrades.com/checkout?plan=any">
                <button className="btn btn-lg">Choose Annual</button>
            </a>
        </div>
    );
}

function PricingCard2Mobile() {
    return (
        <div
            id="cardmobile"
            className="analyst container-fluid marginless body"
        >
            <div className="padding" />
            <div className="row">
                <div className="col-5 marginless">
                    <div id="image">
                        <div id="person" />
                    </div>
                    <h2 id="analyst_h2">
                        <b>$50/month</b>
                    </h2>
                    <h2 id="analyst_h2">
                        <b>Or</b>
                    </h2>
                    <h2 id="analyst_h2">
                        <b>$500/year</b>
                    </h2>
                </div>
                <div className="col-7 marginless">
                    <h1>
                        <b>Analyst</b>
                    </h1>
                    <ul className="list-unstyled">
                        <li>All of Trader Tier PLUS</li>
                        <li>Watchlist</li>
                        <li>Specific Trade Options</li>
                    </ul>
                    <a href="https://www.chancetrades.com/checkout?plan=an">
                        <button className="btn btn-lg">Choose Monthly</button>
                    </a>
                    <h2 id="analyst_or">
                        <b>Or</b>
                    </h2>
                    <a href="https://www.chancetrades.com/checkout?plan=any">
                        <button className="btn btn-lg">Choose Annual</button>
                    </a>
                </div>
            </div>
            <div className="padding" />
        </div>
    );
}

function PricingCard3() {
    return (
        <div id="card" className="quant">
            <h1 id="quant">Quant</h1>
            <div id="person" />
            <ul>
                <li>All of Analyst Tier PLUS</li>
                <li>CallsOrPuts.com Lifetime Membership</li>
            </ul>
            <h2>$1500/lifetime</h2>
            <div className="quant-space"></div>
            <div className="btn-container">
                <a href="https://www.chancetrades.com/checkout?plan=qu">
                    <button className="btn btn-lg">Choose Quant</button>
                </a>
            </div>
        </div>
    );
}

function PricingCard3Mobile() {
    return (
        <div id="cardmobile" className="quant container-fluid marginless">
            <div className="padding" />
            <div className="row">
                <div className="col-5 marginless">
                    <div id="image">
                        <div id="person" />
                    </div>
                    <h2>
                        <b>$1500/lifetime</b>
                    </h2>
                </div>
                <div className="col-7 marginless">
                    <h1>
                        <b>Trader</b>
                    </h1>
                    <ul className="list-unstyled">
                        <li>All of Analyst Tier PLUS</li>
                        <li>CallsOrPuts.com Lifetime Membership</li>
                    </ul>
                    <div id="quant_button">
                        <a href="https://www.chancetrades.com/checkout?plan=td">
                            <button className="btn btn-lg">Choose Quant</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Pricing() {
    return (
        <div className="row">
            <div className="col-sm d-none d-sm-block">
                <PricingCard1 />
            </div>
            <div className="col-sm d-block d-sm-none marginless">
                <PricingCard1Mobile />
            </div>
            <div className="col-sm d-none d-sm-block">
                <PricingCard2 />
            </div>
            <div className="col-sm d-block d-sm-none marginless">
                <PricingCard2Mobile />
            </div>
            <div className="col-sm d-none d-sm-block">
                <PricingCard3 />
            </div>
            <div className="col-sm d-block d-sm-none marginless">
                <PricingCard3Mobile />
            </div>
        </div>
    );
}

function More() {
    return (
        <div id="more" className="row">
            <div className="col">
                <h1>Interested In More?</h1>
            </div>
        </div>
    );
}

function MoreDetail() {
    return (
        <div id="more_detail" className="row">
            <div className="col-sm">
                <ul className="text">
                    <li>1-on-1 Mentoring</li>
                    <li>1 Live Trading Session</li>
                    <li>Lifetime ChanceTrades Option Alerts</li>
                    <li>Lifetime CallsOrPuts.com Option Sweeps</li>
                </ul>
            </div>
            <div className="col-sm">
                <img src="/coin.png" alt="coin" />
                <ul className="coin">
                    <li>Inquire at info@chanceholdings.org</li>
                    <li className="sub_text">
                        Custom Pricing Available Starting at $4,000
                    </li>
                </ul>
            </div>
        </div>
    );
}

function Partners() {
    return (
        <div id="partners" className="row">
            <div className="col">
                <h1>Affiliates & Partners</h1>
            </div>
        </div>
    );
}

function PartnersDetail() {
    return (
        <div id="partners_detail" className="row">
            <div className="col-sm">
                <a
                    href="https://join.robinhood.com/chanl6"
                    className="lnk"
                    target="blank"
                >
                    <img src="/robinhood.png" alt="robinhood" />
                    <h2>Robinhood</h2>
                    <p>Free Stock Awaiting! Click Here!</p>
                </a>
            </div>
            <div className="col-sm">
                <a
                    href="https://act.webull.com/k/G3bulD6phjbH/main"
                    className="lnk"
                    target="blank"
                >
                    <img src="/webull.png" alt="webull" />
                    <h2>Webull</h2>
                    <p>Free Stock Awaiting! Click Here!</p>
                </a>
            </div>
            <div className="col-sm">
                <a
                    href="https://callsorputs.com"
                    className="lnk"
                    target="blank"
                >
                    <img src="/callsorputs.png" alt="calls or puts" />
                    <h2>Calls or Puts</h2>
                </a>
            </div>
        </div>
    );
}

const Home = () => {
    return (
        <div className="container">
            <Hero />
            <SubscribeArea />
            <Pricing />
            <More />
            <MoreDetail />
            <Partners />
            <PartnersDetail />
        </div>
    );
};

export default Home;
