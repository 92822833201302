import React from "react";

const TS = () => {
    return (
        <div>
            <br />
            <h3>Terms of Service</h3>
            <br />
            <p>REGISTRATION</p>
            <p>
                This User Agreement between Authorized User (“You”) and Chance
                Holdings LLC ( "Chance Trades" ) (“the Site”) and any of our
                Site affiliates, agencies, sponsors, licensees or content
                partners (hereafter, collectively referred to as “Chance
                Trades”, “We”, or “Us”) governs your use of these information
                services and web sites (collectively referred to as “the
                Tools”), including any document, content, services, functions or
                features accessible on or through the Site (the “Products”).
                Your use of the Site and the Tools is subject to the terms and
                conditions hereof. By using the Site or any other website owned,
                operated, licensed, or controlled by Us, you agree to be bound
                by the terms of the User Agreement. Other provisions that govern
                your use of the Site may be set forth in online notices
                appearing in connection with certain Products (collectively, the
                “Additional Terms”), all of which are incorporated by reference
                into this User Agreement. Your use of any Products that are
                subject to Additional Terms constitutes your acceptance of the
                respective Additional Terms.
            </p>
            <p>
                If you do NOT agree with any the following Terms, do NOT use the
                Product associated with them.
            </p>
            <p>
                1) BY REGISTERING FOR OR USING Chance Trades , THE USER ACCEPTS
                ALL OF THE TERMS (the “TERMS OF SERVICE”). USE OF THIS SITE WILL
                CONSTITUTE THE USER’S ACCEPTANCE OF ALL OF SUCH TERMS OF SERVICE
                AND THE USER IS RESPONSIBLE ON CHECKING FOR UPDATED TERMS.
            </p>
            <p>
                2) YOU AGREE TO PAY THE SUBSCRIPTION FEE FOR THE USE OF THE
                SERVICES OFFERED BY Chance Trades
            </p>
            <p>
                3) YOU AGREE NOT TO SHARE ACCESS TO YOUR ACCOUNT AND NOT TO
                REDISTRIBUTE ANY DATA YOU RECEIVE THROUGH YOUR USE OF THE SITE
                OR PRODUCTS. IF CAUGHT BREAKING THESE RULES LEGAL ACTION MIGHT
                BE TAKEN AGAINST YOU AND YOU ACCEPT FULL RESPONSIBILITY TO PAY
                THE FEES FOR DAMAGE DETERMINED BY Chance Trades
            </p>
            <p>
                4) YOU MAY NOT COPY, MODIFY, REPRODUCE, REPUBLISH, DISTRIBUTE,
                TRANSMIT OR USE THE SITE Chance Trades OR THE PRODUCTS FOR
                COMMERCIAL OR OTHER PURPOSES, EXCEPT TO THE EXTENT REQUIRED IN
                ORDER FOR YOU TO USE THE SITE AND THE PRODUCTS IN THE MANNER
                EXPRESSLY PROVIDED HEREIN. YOU MAY NOT SHARE YOUR PASSWORD WITH
                ANYONE. OUR SECURITY SYSTEM CHECKS FOR SIMULTANEOUS LOGINS FROM
                MULTIPLE LOCATIONS. IF IT IS FOUND THAT YOU ARE SHARING YOUR
                ACCOUNT, THE SYSTEM WILL AUTO-LOCK THE ACCOUNT FOR 1-7 DAYS AS
                REQUIRED BY OUR DATA PROVIDER AGREEMENTS. YOU MAY NOT UTILIZE
                AUTOMATED SYSTEMS OR SOFTWARE TO EXTRACT DATA FROM Chance
                Trades. YOU MAY NOT STORE OR DOWNLOAD DATA FOR OFFLINE USAGE.
            </p>
            <p>
                5) IF REGISTERING FOR USE OF Chance Trades THROUGH THE ONLINE
                REGISTRATION PROCESS, THE USER VERIFIES THAT THE INFORMATION
                PROVIDED BY THE USER IS ACCURATE AND CORRECT TO THE BEST OF THE
                USER’S KNOWLEDGE.
            </p>
            <p>
                6) Chance Trades USES COOKIES, LOCALSTORAGE, SESSIONSTORAGE ON
                YOUR COMPUTER ACKNOWLEDGING YOUR ACCEPTANCE OF THESE TERMS AND
                CONDITIONS. IF YOUR COMPUTER ACCEPTS COOKIES, YOU MAY NOT BE
                ASKED TO REVIEW THIS AGREEMENT EVERY TIME YOU SIGN ON, BUT YOU
                SHOULD UNDERSTAND THAT YOUR USE OF THE SITE WILL ALWAYS BE
                SUBJECT TO AND PURSUANT TO THIS AGREEMENT. YOU MAY REVIEW THIS
                AGREEMENT AT ANY TIME BY CLICKING ON “TERMS” FROM OUR HOME PAGE.
            </p>
            <p>7) TRADING IS RISKY = NO REPRESENTATIONS OR WARRANTIES</p>
            <p>
                NEITHER Chance Trades, ITS AGENCIES, ATTORNEYS, MEMBERS,
                AFFILIATES, NOR ANY CONTENT PARTNER MAKES ANY WARRANTY OR
                REPRESENTATION THAT THE INFORMATION IS ACCURATE AND TIMELY. YOU
                UNDERSTAND THAT USE OF THE SITE AND OF THE PRODUCTS IS
                UNDERTAKEN AT YOUR OWN RISK. ANY INFORMATION OR DATA PROVIDED BY
                Chance Trades or Chance Trades.COM IS NOT EXHAUSTIVE OR
                COMPLETE. NEITHER Chance Trades NOR ANY CONTENT PARTNER ASSUMES
                ANY LIABILITY FOR THE USE OR INTERPRETATION OF ANY PRODUCT. THE
                SITE AND THE PRODUCTS ARE PROVIDED “AS IS” WITHOUT WARRANTY OF
                ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, IMPLIED WARRANTIES WITH RESPECT TO PERFORMANCE,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ACCURACY, OMISSIONS,
                COMPLETENESS, CURRENTNESS, OR TIMELINESS. YOUR EXCLUSIVE REMEDY
                UNDER THIS AGREEMENT , IF ANY, FOR ANY CLAIM FOR DAMAGES
                RELATING TO YOUR USE OF THE SITE OR THE PRODUCTS, WHETHER ON THE
                BASIS OF CONTRACT, NEGLIGENCE, OR ANY OTHER BASIS, WILL BE
                LIMITED TO THE AGGREGATE AMOUNT OF CHARGES PAID BY YOU TO Chance
                Trades DURING THE 12 ¬MONTH PERIOD PRECEDING THE EVENT GIVING
                RISE TO SUCH CLAIM. YOU ACKNOWLEDGE THAT PROVISION OF THE SITE
                AND CONTENT ENTAILS THE LIKELIHOOD OF SOME HUMAN AND MACHINE
                ERRORS, DELAYS, INTERRUPTIONS AND LOSSES, INCLUDING THE
                INADVERTENT LOSS OF DATA, SERVER DOWN TIME, BUGS WHICH Chance
                Trades WILL WORK TO FIX AS FAST AS POSSIBLE.
            </p>
            <p>8) LIMITATION OF LIABILITY</p>
            <p>
                Chance Trades IS NOT A REGISTERED BROKER-DEALER OR A REGISTERED
                INVESTMENT ADVISER. YOU UNDERSTAND THAT THIS WEB SITE AND
                CONTENT IS FURNISHED FOR YOUR PERSONAL, NONCOMMERCIAL,
                INFORMATIONAL PURPOSES ONLY, AND THAT NO MENTION OF A PARTICULAR
                SECURITY IN THIS WEB SITE AND CONTENT CONSTITUTES A
                RECOMMENDATION TO BUY, SELL, OR HOLD THAT OR ANY OTHER SECURITY,
                OR THAT ANY PARTICULAR SECURITY, PORTFOLIO OF SECURITIES,
                TRANSACTION OR INVESTMENT STRATEGY IS SUITABLE FOR ANY SPECIFIC
                PERSON. WE ADVISE EVERYONE TO KNOW THE RISKS INVOLVED WITH
                TRADING STOCKS AND/OR OPTIONS. WE ENCOURAGE EVERY VISITOR TO THE
                WEBSITE TO DO HIS/HER OWN RESEARCH AND TO CONSULT WITH HIS/HER
                OWN FINANCIAL ADVISOR(S) PRIOR TO ENGAGING IN ANY INVESTING
                ACTIVITIES, AND TO MAKE SURE HE/SHE KNOWS ALL THE POTENTIAL
                RISKS INVOLVED. ANY INVESTMENT DECISION THAT RESULTS IN LOSSES
                OR GAINS MADE BASED ON ANY INFORMATION ON THIS SITE, OR RELATED
                SERVICES IS NOT THE RESPONSIBILITY OF Chance Trades. Chance
                Trades AND ALL OF ITS CONTENTS ARE COPYRIGHTED. THE VIEWER OF
                THIS SITE IS GIVEN A LIMITED LICENSE TO USE ITS CONTENTS FOR
                PERSONAL OR INTERNAL COMPANY USE ONLY.
            </p>
            <p>REFUNDS POLICY</p>
            <p>
                Since services are non¬-tangible, irrevocable, digital goods; we
                do not issue refunds, which you are solely responsible for
                understanding. During checkout, upon clicking the final submit
                button, your purchase is charged to the credit/debit card that
                you supplied. All sales are final. You cannot cancel a purchase
                or receive a refund for a purchase. You can cancel future
                recurring subscription payments at any time by cancellation
                through your account settings page. You may cancel at any time
                from your account or reach out to our team if you have any
                questions about the process.
            </p>
            <p>
                If you are under a free or discounted trial period, you will be
                charged at the end of your trial unless cancelled. We do not
                provide refunds. All sales are final. it is your responsibility
                to manage your subscription accordingly.
            </p>
            <p>Chance Trades</p>
            <p>
                Your use of ideas, systems, and/or data provided by Chance
                Trades are at your own risk and it is your sole responsibility
                to evaluate the accuracy, completeness, and usefulness of the
                information. Feel free to reach out to our team if you ever
                think we can help or answer any of your questions.
            </p>
        </div>
    );
};

export default TS;
