import React from "react";

const Cookie = () => {
    return (
        <div>
            <br />
            <h2>Cookie Use Policy</h2>
            <br />
            <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service.
            </p>
            <p>
                You can instruct Your browser to refuse all Cookies or to
                indicate when a Cookie is being sent. However, if You do not
                accept Cookies, You may not be able to use some parts of our
                Service.
            </p>
            <p>
                Cookies can be “Persistent” or “Session” Cookies. Persistent
                Cookies remain on your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close your web browser.
            </p>
            <p>
                We use both session and persistent Cookies for the purposes set
                out below:
            </p>
            <p>
                Necessary / Essential Cookies Type: Session Cookies Administered
                by: Us Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
                Cookies Policy / Notice Acceptance Cookie Type: Persistent
                Cookies Administered by: UsPurpose: These Cookies identify if
                users have accepted the use of cookies on the Website.
                Functionality CookiesType: Persistent CookiesAdministered by:
                UsPurpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website. Tracking and Performance CookiesType: Persistent
                CookiesAdministered by: Third-PartiesPurpose: These Cookies are
                used to track information about traffic to the Website and how
                users use the Website. The information gathered via these
                Cookies may directly or indirectly identify you as an individual
                visitor. This is because the information collected is typically
                linked to a pseudonymous identifier associated with the device
                you use to access the Website. We may also use these Cookies to
                test new advertisements, pages, features or new functionality of
                the Website to see how our users react to them.
            </p>
        </div>
    );
};

export default Cookie;
